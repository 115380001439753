import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import * as styles from '../styles/styleconstants';
import * as color from "../styles/color.js";

const gridstyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: "1rem",
    allignContent: "stretch",
  }
const gridItem = {
    background: "#FFF3EB",
    paddingLeft: "10px",
    borderRadius: "12px",
    fontSize: "13px",
  }

const titleLine = {
    fontWeight: "150",
    fontSize: "14px"
}

const workTraits = {
    borderLeft: "medium solid #979FAF",
    listStyleType: "none"
}



export default function Works() {
    return (
      <Layout>
      <main style = {styles.pageStyles}>
          <title> Works </title>
          <h1 style= {styles.headingStyles}>
              Works
          </h1>

          <h3 style = {styles.categoryStyle}> Large Ensemble </h3>
          <div style={gridstyle}>
              <div style = {gridItem}>
                <p style={titleLine}>Violin Concerto | 2019/2020</p>
                    <ul style = {workTraits}>
                        <li>for solo violin and 16 players</li>
                        <li>commissioned by Tim Weiss and the Oberlin Contemporary Ensemble for Herdís Mjöll Guðmundsdóttir</li>
                        <li>duration: ca. 26 minutes</li>
                        <li> <a href="https://youtu.be/oC-VyD8JnXM?t=364" style={styles.altLinkStyle}>watch</a> | <a href="https://issuu.com/liamkaplan/docs/violin_concerto_score_20210109__1_" style = {styles.altLinkStyle}>score</a> </li>
                    </ul>
              </div>

              <div style={gridItem}>
                <p style = {titleLine}>Rhapsody on a Chorale | 2018</p>
                <ul style = {workTraits}>
                    <li>for string orchestra</li>
                    <li>duration: ca. 14 minutes</li>
                    <li><a href="https://soundcloud.com/liam-kaplan-961650380/rhapsody-on-a-chorale" style={styles.altLinkStyle}>listen</a> </li>
                </ul>
              </div>
          </div>
          <br></br>

          <h3 style = {styles.categoryStyle}>Duo</h3>

        <div style={gridstyle}>
            <div style = {gridItem}>
            <p style={titleLine}>New Hampshire Songs | 2019</p>
                <ul style = {workTraits}>
                    <li>for soprano or mezzo-soprano and piano</li>
                    <li>commissioned by and written for Diana Schwam</li>
                    <li>duration: ca. 26 minutes</li>
                    <li><a href="https://issuu.com/liamkaplan/docs/new_hampshire_songs_20211129_1" style={styles.altLinkStyle}>score</a></li>
                </ul>
            </div>

            <div style={gridItem}>
            <p style = {titleLine}>Fantasy Piece | 2018</p>
            <ul style = {workTraits}>
                <li>for cello and piano</li>
                <li>commissioned by and written for Trisha Doo</li>
                <li>duration: ca. 5 minutes</li>
                <li><a href="https://youtu.be/SEPaMCYMEhU" style={styles.altLinkStyle}>watch</a> | <a href = "https://gumroad.com/liamkaplan#umxGn" style= {styles.altLinkStyle}>buy</a></li>
            </ul>
            </div>

        </div>
        <br></br>



          <h3 style = {styles.categoryStyle}>Solo</h3>

          <div style={gridstyle}>
              <div style = {gridItem}>
                <p style={titleLine}>8 Preludes | 2018</p>
                    <ul style = {workTraits}>
                        <li>for solo piano</li>
                        <li>commissioned by and written for Amber Scherer</li>
                        <li>duration: ca. 8 minutes</li>
                        <li><a href="https://open.spotify.com/track/19zUslbTnSk0xDFYTRR4AG" style={styles.altLinkStyle}>listen</a> | <a href = "https://gumroad.com/liamkaplan#AHvXn" style={styles.altLinkStyle}>buy</a></li>
                    </ul>
              </div>

              <div style={gridItem}>
                <p style = {titleLine}>Scherzo | 2018</p>
                <ul style = {workTraits}>
                    <li>for solo piano</li>
                    <li>commissioned by and written for Stephen Joven-Lee</li>
                    <li>duration: ca. 4 minutes</li>
                    <li><a href="https://youtu.be/6DwZnMSckrs" style={styles.altLinkStyle}>watch</a> | <a href = "https://gumroad.com/liamkaplan#yAQDd" style={styles.altLinkStyle}>buy</a></li>
                </ul>
              </div>

              <div style = {gridItem}>
                <p style={titleLine}>Prelude in E | 2018</p>
                    <ul style = {workTraits}>
                        <li>for solo classical guitar</li>
                        <li>commissioned by and written for Mohit Lauer Dubey</li>
                        <li>duration: ca. 5 minutes</li>
                    </ul>
              </div>

              <div style={gridItem}>
                <p style = {titleLine}>Sparks | 2017</p>
                <ul style = {workTraits}>
                    <li>for solo piano</li>
                    <li>duration: ca. 4 minutes</li>
                    <li><a href="https://youtu.be/pW_ouewImoU" style={styles.altLinkStyle}>watch</a> | <a href = "https://gumroad.com/liamkaplan#CrNT" style={styles.altLinkStyle}>buy</a></li>
                </ul>
              </div>

              <div style={gridItem}>
                <p style = {titleLine}>Variations on an Original Theme | 2016</p>
                <ul style = {workTraits}>
                    <li>for solo piano</li>
                    <li>duration: ca. 10 minutes</li>
                    <li><a href="https://soundcloud.com/liam-kaplan-961650380/variations-on-an-original-theme" style={styles.altLinkStyle}>listen</a> | <a href = "https://gumroad.com/liamkaplan#ZpRlN" style={styles.altLinkStyle}>buy</a></li>
                </ul>
              </div>

              <div style={gridItem}>
                <p style = {titleLine}>Piano Sonata No. 1 | 2015</p>
                <ul style = {workTraits}>
                    <li>for solo piano</li>
                    <li>duration: ca. 8 minutes</li>
                    <li><a href="https://youtu.be/rvyUNbYbTpw" style={styles.altLinkStyle}>watch</a> | <a href = "https://gumroad.com/liamkaplan#ltdZ" style={styles.altLinkStyle}>buy</a></li>
                </ul>
              </div>

          </div>

         
          <p style = {styles.bottomSpacer}> </p>
      </main>
      </Layout>
    )
  }